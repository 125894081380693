import { SEO } from '@ui/SEO';
import { NotFound } from '@views/not-found';
import { promises as fs } from 'fs';
import { GetStaticPropsContext, NextPage } from 'next';
import path from 'path';

export const getStaticProps = async ({ locale }: GetStaticPropsContext) => {
  const filePath = path.join(
    process.cwd(),
    `public/translations/${locale}.json`,
  );
  const jsonData = await fs.readFile(filePath, 'utf8');
  const data = JSON.parse(jsonData);

  return {
    props: {
      locale: {
        title: data['seo.404.title'],
        description: data['seo.404.description'],
      },
    },
  };
};

interface Props {
  locale: {
    title: string;
    description: string;
  };
}

const NotFoundPage: NextPage<Props> = ({ locale }) => {
  return (
    <>
      <SEO title={locale.title} description={locale.description} />
      <NotFound />
    </>
  );
};

export default NotFoundPage;
